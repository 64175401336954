import useSWR from 'swr';
import {priceHelper} from '@helpers/config-master.mjs';
import {dealerShippingCost} from '@helpers/checkout-v3/shipping/update-shipping-cost-functions.mjs';
import {fetcher} from '@helpers/common/fetch.mjs';
import {createContext, useContext, useState} from 'react';
import {deleteClientSideCookie, readCookie} from '@components/widgets/Cookies';
import {getCartTotals} from '@helpers/checkout-v2/cart/get-cart.mjs';
import {arraySum} from '@helpers/common/array.mjs';
import {
  addCartItemInLineItems,
  addMultipleCartItemsInLineItems,
  deleteItemFromLineItems,
  updateCartItemInLineItems
} from '@components/cart/CartProviderHeleprs.jsx';
import {useSession} from 'next-auth/react';
import {usePreviousRoute} from '@helpers/hooks/usePreviousRoute';

export const CartContextV3 = createContext(null);

export const useCartLineItems = () => {
  const {
    data,
    mutate: mutateCart,
    isLoading,
    isValidating
  } = useSWR('/api/cartv2', fetcher, {
    fallbackData: null,
    revalidateOnFocus: true,
    revalidateOnMount: true,
    revalidateOnReconnect: true
  });

  const isLoaded = typeof data !== 'undefined';
  const isLoadedOnce = !isLoading && isLoaded;

  const lineItems = data?.data?.items ?? [];
  const lineItemsSumPrice = arraySum(lineItems, (x) => {
    const price = Number(x?.price);
    const quantity = Number(x?.quantity);
    return price * quantity;
  });

  const cartToken = data?.data?.cart_token;
  if (typeof window !== 'undefined') {
    // consoleLogFe('[useCartLineItems][CartToken from API Response]:', cartToken);
  }

  return {
    isLoading,
    isLoadedOnce,
    isValidating,
    data,
    lineItems,
    mutateCart,
    lineItemsSumPrice,
    cartToken
  };
};

export const DatabaseCartProviderV3 = ({children}) => {
  const {data: session, status} = useSession();
  const [isPushing, setPushing] = useState(false);
  const [isAddingToCart, setIsAddingToCart] = useState(false);
  const prevRoute = usePreviousRoute();

  // Initialize an empty promise to start the queue
  let addToCartQueue = Promise.resolve();
  let operationsCount = 0;

  const hasLoadedSession = status?.sessionStatus !== 'loading';
  const isDealer = session?.user?.isDealer;
  const accountId = session?.user?.account_id;

  let {cartToken, lineItems, isLoadedOnce, mutateCart, isValidating} = useCartLineItems();

  const clientCartToken = readCookie('sa_cart_token');
  // console.log('[CART PROVIDER][clientCartToken]:', clientCartToken);
  // console.log('[CART PROVIDER][cartToken]:', cartToken);

  if (clientCartToken && cartToken) {
    if (clientCartToken !== cartToken) {
      deleteClientSideCookie('sa_cart_token');
      deleteClientSideCookie('sa_checkout_token');
      // leave this, protects and creates new cart token if one exists after order is placed, etc.
    }
  }


  const isFetchingShippingEstimate = false;
  // let {shippingEstimate, isFetchingShippingEstimate} = useShippingCost() // @TODO - NEED TO FIX
  let shippingEstimate = 0.0;
  if (isDealer) {
    shippingEstimate = dealerShippingCost;
  }

  const isEmpty = !lineItems?.length;
  const isLoading = !isLoadedOnce || isValidating || isFetchingShippingEstimate;
  isLoadedOnce = !isLoading;

  const addMultipleItemsToCart = async (data) => {
    try {
      setPushing(true);
      setIsAddingToCart(true);
      const mutateRes = await mutateCart();
      const currentLineItems = mutateRes?.data?.items ?? [];

      const itemsToAdd = data?.items;

      sendAddToCartEvent({
        items: itemsToAdd,
        totalPrice: data?.totalPrice,
        eventData: {
          path: window?.location?.pathname,
          page: {
            page_title: document?.title,
            page_referrer: prevRoute ?? null,
            page_location: window?.location?.href ?? null,
            page_path: window?.location?.pathname ?? null,
            page_hostname: window?.location?.hostname ?? null
          }
        }
      });

      const newCartItems = addMultipleCartItemsInLineItems(currentLineItems, itemsToAdd);
      await syncCartWithDB({
        items: newCartItems
      });
      await mutateCart();
    } catch (error) {
      console.error('Error adding to cart:', error);
    } finally {
      setPushing(false);
      setIsAddingToCart(false);
    }
  };

  const addToCart = async (addToCartData) => {
    // console.log('[addToCartData]:', addToCartData);
    try {
      setPushing(true);
      setIsAddingToCart(true);

      // Add the current add-to-cart operation to the queue
      addToCartQueue = addToCartQueue.finally(async () => {
        try {
          const mutateRes = await mutateCart();
          // console.log('[mutateRes]:', mutateRes);

          const currentLineItems = mutateRes?.data?.items ?? [];
          const addedItem = addToCartData?.item;

          sendAddToCartEvent({
            item: addedItem,
            totalPrice: addedItem?.price,
            eventData: {
              path: window?.location?.pathname,
              page: {
                page_title: document?.title,
                page_referrer: prevRoute ?? null,
                page_location: window?.location?.href ?? null,
                page_path: window?.location?.pathname ?? null,
                page_hostname: window?.location?.hostname ?? null,
                page_search: window?.location?.search ?? null,
                page_hash: window?.location?.hash ?? null
              }
            }
          });

          const newCartItems = addCartItemInLineItems(currentLineItems, addedItem);
          await syncCartWithDB({
            items: newCartItems
          });
          await mutateCart();
        } catch (error) {
          console.error('Error adding to cart:', error);
        } finally {
          setPushing(false);
          setIsAddingToCart(false);
          operationsCount--; // Decrement counter after operation completes
          if (operationsCount === 0) {
            // If this is the last operation, open the cart
            openCart();
          }
        }
      });

      await addToCartQueue; // Wait for the queue before clearing loading state
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const updateLocalItem = async (updatedItem) => {
    // console.log("[UpdateCartItem]:", {updatedItem});
    setPushing(true);
    const newCartItems = updateCartItemInLineItems(lineItems, updatedItem);
    await syncCartWithDB({
      items: newCartItems
    });
    await mutateCart();
    setPushing(false);
  };

  const deleteLocalItem = async (sku) => {
    // console.log("[cartProviderV3][deleteLocalItem][lineItems]:", {initialCartItems: lineItems});
    setPushing(true);
    const newCartItems = deleteItemFromLineItems(lineItems, sku);
    // console.log('[deleteLocalItem][newCartItems]:', {newCartItems});
    await syncCartWithDB({
      items: newCartItems
    });
    await mutateCart();
    setPushing(false);
  };

  const cartTotals = getCartTotals(lineItems, {isDealer}, shippingEstimate);
  const shippingTotal = cartTotals?.shippingTotal ?? 0;
  const isSaleEnabled = priceHelper?.saleEnabled;

  lineItems = attachPreorderInfoToCartLineItems(lineItems);

  const cartItemsSimple = lineItems?.map((item) => {
    return {
      sku: item?.sku,
      price: item?.price,
      quantity: item?.quantity,
      price_map: item?.price_map,
      price_msrp: item?.price_msrp,
      product_id: item?.product_id,
      total_line_discounts: item?.total_line_discounts
    };
  });

  const providedValue = {
    hasLoadedSession: hasLoadedSession ? true : false,
    session,
    isDealer,
    accountId,
    cart_token: cartToken,
    cartItemsSimple,
    lineItems,
    isLoadedOnce,
    isLoading,
    isValidating,
    isPushing,
    isAddingToCart,
    setIsAddingToCart,
    isEmpty,
    isSaleEnabled,
    addToCart,
    addMultipleItemsToCart,
    updateLocalItem,
    deleteLocalItem,
    mutateCart,
    shippingTotal: shippingTotal,
    totals: cartTotals
  };
  // console.log("cartProvider Values: ", {providedValue})
  return <CartContextV3.Provider value={providedValue}>{children}</CartContextV3.Provider>;
};

export function useDatabaseCartProviderV3() {
  const context = useContext(CartContextV3);
  if (!context) {
    throw new Error('useDatabaseCartProviderV3 must be used within a DatabaseCartProviderV3');
  }
  return context;
}

export const syncCartWithDB = async (payload) => {
  const response = await fetch('/api/cartv2', {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(payload)
  });
  const res = await response.json();
  return res;
};

const sendAddToCartEvent = (eventPayload) => {
  // console.log('[sendAddToCartEvent][eventPayload]:', eventPayload);
  return fetch('/api/analytics/events/add-to-cart', {
    method: 'POST',
    body: JSON.stringify(eventPayload),
    headers: {'Content-Type': 'application/json'}
  });
};

export const getShippingEstimateFromApiOnBackend = async (cartToken) => {
  try {
    const response = await fetch(`/api/carriers/get-rate-estimate?cartToken=${cartToken}`);
    const data = await response.json();
    // console.log('[data] inside ajax Response [getShippingEstimateFromApiOnBackend] => ', {data})

    let rateEstimate;
    if (data) {
      rateEstimate = Number(data?.rate_estimate);
    } else {
      rateEstimate = null;
    }

    return {rateEstimate, code: data?.code};
  } catch (error) {
    console.error('Error fetching shipping estimate:', error);
    throw error;
  }
};

export const attachPreorderInfoToCartLineItems = (lineItems) => {
  // console.log("[attachPreorderInfoToCartLineItems]:", {lineItems})
  const variants = lineItems?.flatMap((item) => (item?.variant ? [item?.variant] : []));
  if (!lineItems || !variants) return lineItems;

  lineItems = lineItems?.map((lineItem) => {
    const matchingVariant = variants?.find((variant) => variant?.sku === lineItem?.sku);
    const preorderInfo = matchingVariant?.preorderInfo;
    return {
      ...lineItem,
      preorderInfo: preorderInfo ?? null
    };
  });
  return lineItems;
};
