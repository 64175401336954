import Link from 'next/link';
import dynamic from 'next/dynamic';
import {VisuallyHidden} from '@reach/visually-hidden';
import CartToggle from '@components/cart/CartToggle.jsx';
import {useState} from 'react';
import {useSession} from 'next-auth/react';
import {classNames} from '@helpers/common/common.mjs';
import {ChevronDown} from '@components/widgets/Icons';
import {useLogoutV2} from '@components/accounts/useLoginLogoutV2';

// Lazy load the search bar
const DesktopSearchV2 = dynamic(
  () => import('@components/search-v2/HeaderSearchBarV2').then((mod) => mod.DesktopSearchV2),
  {
    ssr: false,
    loading: () => <SearchBarLoading />
  }
);

// PreHeaderBar @ADA via ChatGPT ✔️
export default function PreHeaderBar({isFallback}) {
  const {logoutV2} = useLogoutV2();
  const {data: session, status} = useSession();
  const isLoggedIn = session && !!session?.user?.email ? true : false;
  const isDealer = isLoggedIn && session?.user?.role === 2;

  const sessionIsLoading = status === 'loading';
  const sessionIsLoaded = sessionIsLoading !== true;

  const accountUrl = '/account';
  const loginUrl = '/account/login';

  const AccountLink = () => (
    <Link
      href={isLoggedIn ? accountUrl : loginUrl}
      className='flex ml-4 text-gray-100 hover:text-white whitespace-nowrap cursor-pointer hover:opacity-70'>
      <div className='w-[20px] h-[20px] mr-1'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          className='w-full h-full'
          viewBox='0 0 75 74.703'
          aria-hidden='true'>
          <path
            d='M78.085,23.194C64.176,26.939,57.55,47.139,65.324,62.1c8.621,16.595,29.235,15.207,36.284-2.442,7.807-19.547-6.127-41.148-23.522-36.465m10.647,5.253c11.745,5.85,14.3,25.613,4.649,35.915C83.75,74.64,69.388,68.109,66.743,52.248,64.1,36.423,76.912,22.56,88.733,28.447M61.168,70.087C48.315,73.544,39.959,91.974,49.213,96.454c1.636.792,1.8.8,33.841.8h32.2l1.594-.934c7.117-4.171,2.5-19-7.565-24.278-4.661-2.447-10.193-3.337-11.533-1.856-1.631,1.8-.189,3.809,2.745,3.823,4.147.019,9.762,3.184,12.221,6.889,2.759,4.155,3.963,9.559,2.465,11.057l-.8.8H82.9c-34.319,0-32.329.125-32.877-2.058C48.355,84.036,57.565,74,65.339,74c2.05,0,3.283-.831,3.283-2.214,0-2.254-2.913-2.917-7.455-1.7'
            transform='translate(-45.284 -22.546)'
            fill='currentColor'
            fillRule='evenodd'
          />
        </svg>
        <VisuallyHidden>{isLoggedIn ? 'My Account' : 'Login'}</VisuallyHidden>
      </div>
      <span className='uppercase font-oswald text-sm'>{isLoggedIn ? 'My Account' : 'Login'}</span>
    </Link>
  );

  return (
    <nav
      className='flex flex-row justify-between menu-bg-color h-[50px]'
      aria-label='Pre header search bar with account login button and cart icon'>
      <div
        className='basis-1/3 flex justify-center content-center items-center
      place-self-center self-center place-content-center'
      />

      <div className='flex basis-1/3 justify-items-end items-center place-content-end'>
        {/*<SearchBar />*/}

        <DesktopSearchV2 />

        <PreHeaderDropDownMenuDesktop main={<AccountLink />}>
          {isLoggedIn && (
            <>
              <DropdownLink href='/account' adaKeyword={'Account Settings'}>
                <span className='uppercase font-oswald text-sm'>Account Settings</span>
              </DropdownLink>
              <DropdownLink href='/account/orders' adaKeyword={'Order History'}>
                <span className='uppercase font-oswald text-sm'>Order History</span>
              </DropdownLink>
              <DropdownLink href='/returns' adaKeyword={'View Returns'}>
                <span className='uppercase font-oswald text-sm'>View Returns</span>
              </DropdownLink>
              {isDealer && (
                <DropdownLink href='/dealers' adaKeyword={'Dealer Quick Order'}>
                  <span className='uppercase font-oswald text-sm'>Dealer Quick Order</span>
                </DropdownLink>
              )}
              <div className='border-t border-neutral-200'>
                <DropdownItem
                  onClick={async () => {
                    await logoutV2();
                  }}>
                  <span className='uppercase font-oswald text-sm'>Logout</span>
                </DropdownItem>
              </div>
            </>
          )}
        </PreHeaderDropDownMenuDesktop>

        <div className='w-16 flex justify-center items-center pr-8'>
          {!isFallback && <CartToggle handleClick={() => {}} />}
        </div>
      </div>
    </nav>
  );
}

// DropdownItem @ADA via ChatGPT ✔️
function DropdownItem({children, onClick}) {
  return (
    <button
      role='button'
      tabIndex='0'
      className='w-full text-left font-large text-black focus:outline-zinc-600
       font-oswald hover:bg-neutral-200 tracking-widest transition-all duration-200 main-submenu
        block px-4 py-2 text-sm font-oswald uppercase cursor-pointer'
      onClick={onClick}>
      {children}
    </button>
  );
}

// DropdownLink @ADA via ChatGPT ✔️
function DropdownLink({children, href, adaKeyword}) {
  return (
    <Link
      href={href}
      role='menuitem'
      aria-label={`Navigate to ${adaKeyword ?? 'Menu Link'}`} // describe where the link goes to
      className='w-full items-center justify-between font-large text-black
       focus:outline-zinc-600 font-oswald hover:bg-neutral-200 tracking-widest
       transition-all duration-200 main-submenu block px-4 py-2 text-sm font-oswald uppercase cursor-pointer'>
      {children}
    </Link>
  );
}

// PreHeaderDropDownMenuDesktop @ADA via ChatGPT ✔️
function PreHeaderDropDownMenuDesktop({main, showChevron = false, children}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className='relative text-left group'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div
        className={classNames(
          'inline-flex justify-center w-full py-2 pl-0',
          'text-lg font-medium text-white focus:outline-none font-bebas cursor-pointer',
          'font-bebas uppercase tracking-widest',
          {
            'opacity-100 pointer-events-auto': isHovered
          }
        )}>
        {main}
        {showChevron && (
          <ChevronDown className='h-5 w-5 mt-1' aria-label='menu dropdown indicator' />
        )}
      </div>
      {isHovered && (
        <div
          className='origin-top-right right-0 opacity-100 pointer-events-auto
           group-hover:opacity-100 group-hover:pointer-events-auto absolute
            rounded z-20 bg-white shadow-lg focus:outline-none whitespace-pre'
          role='menu'
          aria-label='Dropdown menu content'>
          {children && <div className='py-1'>{children}</div>}
        </div>
      )}
    </div>
  );
}

const SearchBarLoading = () => {
  return (
    <div className='w-full'>
      <div className='max-w-[276px] w-full'>
        <div className='flex items-center justify-between mx-auto text-gray-600 bg-white h-10 rounded-sm'>
          <label className='visually-hidden' aria-label='Search' htmlFor='headerSearchBar'>
            Search
          </label>
          <input
            className='border-1 border-gray-300 bg-white h-10 px-3 pr-2 focus:outline-none remove-webkit-appearance'
            type='text'
            placeholder='Search'
            autoComplete='off'
            id='headerSearchBar'
          />

          <button aria-label='Search Button' title='Search' type='submit' className='pr-4'>
            <svg
              className='text-gray-600 h-5 w-5 fill-current'
              xmlns='http://www.w3.org/2000/svg'
              version='1.1'
              id='Capa_1'
              x='0px'
              y='0px'
              viewBox='0 0 56.966 56.966'
              width='512px'
              height='512px'>
              <path d='M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z' />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
