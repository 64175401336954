import {Fragment} from 'react';
import {Dialog, Transition} from '@headlessui/react';

export const Popup = ({isOpen, closeModal, children}) => {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as='div' className='relative z-20' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'>
            <div className='fixed inset-0 bg-black bg-opacity-50' />
          </Transition.Child>

          <div className='fixed inset-1 overflow-y-auto'>
            <div className={'absolute w-full bottom-3 justify-center p-4 text-center'}>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300 transform'
                enterFrom='opacity-0 scale-95 translate-y-12'
                enterTo='opacity-100 scale-100 translate-y-0'
                leave='ease-in duration-200 transform'
                leaveFrom='opacity-100 scale-100 translate-y-12'
                leaveTo='opacity-0 scale-95 translate-y-0'>
                <Dialog.Panel
                  className='w-full md:max-w-md lg:max-w-lg transform overflow-hidden rounded-2xl
                bg-white p-2 text-left align-middle shadow-xl transition-all relative lg:absolute
                 left-1/2 -translate-x-1/2  lg:-translate-y-full '>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export const CloseXButton = ({closeModal}) => {
  return (
    <div onClick={closeModal} className='absolute top-4 right-4 cursor-pointer text-gray-600'>
      <svg
        xmlns='http://www.w3.org/2000/svg'
        className={'h-7 w-7'}
        viewBox='0 0 20 20'
        fill='currentColor'>
        <path
          fillRule='evenodd'
          d='M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z'
          clipRule='evenodd'
        />
      </svg>
    </div>
  );
};
