import {useEffect, useState} from 'react';
import {useCartUI} from '@components/cart/CartSideCartUiProvider';
import {CheckMarkAnimation} from '../mobile/CheckMarkAnimation';
import {AddToCart_GA4} from '@components/datalayer/google';
import {useDatabaseCartProviderV3} from '@components/cart/CartProvider.jsx';
import {Spinner} from '@components/widgets/Common';
import {AddToCart_ADWORDS} from '@components/datalayer/adwords';

export const AddToCartButton = ({
  selectedVariant,
  className,
  disabled,
  children,
  addToCartButtonSource,
  quantity = 1,
  spinnerSize = 24,
  showSpinner = true,
  icon,
  handleLoading,
  handleDone
}) => {
  const {hasLoadedSession, session, addToCart} = useDatabaseCartProviderV3();
  const {openCart} = useCartUI();
  const [isLoading, setLoading] = useState(false);
  const [wasAdded, setWasAdded] = useState(false);

  useEffect(() => {
    if (wasAdded === true) {
      openCart();
      setTimeout(() => {
        setWasAdded(false);
        const cartElement = document.getElementById('cart');
        if (cartElement) cartElement.focus();
      }, 1200);
    }
  }, [wasAdded]);

  const addToCartHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    handleLoading && handleLoading();

    try {
      const preorderInfo = selectedVariant?.preorderInfo;
      // console.log('[AddToCartButton] preorderInfo: ', selectedVariant);
      const isPreOrderedItem =
        preorderInfo?.is_pre_order_eligible === true && preorderInfo?.is_pre_order_enabled === 1;
      // console.log("[AddToCartButton] isPreOrderItem: ", isPreOrderItem)
      const shouldSendAsPreOrderedItem = !selectedVariant?.currentlyInStock && isPreOrderedItem;

      const payload = {
        item: {
          sku: selectedVariant?.sku,
          is_pre_ordered_item: shouldSendAsPreOrderedItem,
          product_type: selectedVariant?.product_type,
          quantity: quantity,
          product_id: selectedVariant?.id ?? selectedVariant?.product_id,
          price: selectedVariant?.price,
          price_map: selectedVariant?.price_map ?? selectedVariant?.priceMap,
          price_msrp: selectedVariant?.price_msrp ?? selectedVariant?.priceMsrp,
          price_dealer: selectedVariant?.price_dealer ?? selectedVariant?.priceDealer
        },
        hasLoadedSession: hasLoadedSession ? true : false,
        session: session ?? null
      };
      // console.log(" >> [AddToCart] payload: ", payload);

      if (addToCartButtonSource) {
        payload.item.source = addToCartButtonSource;
      }

      await addToCart(payload);
      AddToCart_GA4(payload);
      AddToCart_ADWORDS(payload);
      // AddToCart_SkarAudio(payload);
      setWasAdded(true);
      openCart();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      handleDone && handleDone();
    }
  };

  return (
    <button
      onClick={addToCartHandler}
      className={className + ' flex align-center justify-center gap-3 whitespace-pre'}
      disabled={disabled || isLoading}
      aria-disabled={disabled || isLoading}>
      {showSpinner && isLoading && (
        <div>
          <Spinner size={spinnerSize} />
        </div>
      )}
      {!isLoading && icon ? <div>{icon}</div> : null}
      {wasAdded && (
        <div>
          <CheckMarkAnimation render={true} size={spinnerSize} />
        </div>
      )}
      {children}
    </button>
  );
};
