import Image from 'next/image';
import {useRouter} from 'next/router.js';
import dynamic from 'next/dynamic';
import cn from 'clsx';
import {CustomerPhotosFromReviewsGrid} from './review-photos/review-photos.jsx';
import {useEffect, useRef, useState} from 'react';
import {SvgStar} from '@components/product/global/Reviews';
import {
  DisplayOneReviewForMobileAndDesktop,
  RatingSummaryDesktop
} from '@components/product/desktop/ReviewsDesktop';
import {RatingSummaryMobile} from '@components/product/mobile/ReviewsMobile';
import {PaginationV2} from '@components/widgets/PaginationV2';
import {HStack, OnlyDesktop, OnlyMobile} from '@components/widgets/Common';
import {useUI} from '@components/widgets/UiProvider';
import {readCookie} from '@components/widgets/Cookies';
import {updateSkuListBasedOnProductParams} from '@helpers/reviews/review-sku-list-functions.mjs';
import {
  ReviewPopupIndividualV2
} from '@components/product/reviews/review-photos/review-popup-individual-v2';
import {
  ReviewsLightboxCarouselMobile
} from '@components/product/reviews/review-photos/reviews-lightbox-carousel-mobile';

const WriteReview = dynamic(() => import('@components/product/reviews/write-review.jsx'), {
  ssr: false
});

const adminIpList = [
  '47.207.68.73', // Work IP 1
  '47.207.68.74', // Work IP 2
  '47.198.22.221', // Kevin's Home IP,
  '97.92.195.103', // Andrew's Home IP
  '127.0.0.1',
  '::1'
];

export const CustomerReviewsFromDb = ({
                                        sku,
                                        skuList,
                                        selectedVariant,
                                        currentVariantReviewData,
                                        isMobile
                                      }) => {
  const router = useRouter();
  const {getReviews} = useReviews();

  const [allReviews, setAllReviews] = useState([]);
  const [page, setPage] = useState(0);
  const [lastFetchedPage, setLastFetchedPage] = useState(0);
  const [reviewsToShow, setReviewsToShow] = useState([]);
  const [showWriteReview, setShowWriteReview] = useState(false);
  const [showWriteReviewButton, setShowWriteReviewButton] = useState(false);

  const {
    closeIndividualReviewModal,
    displayIndividualReviewModal,
    displayIndividualReviewModalMobile,
    initialReviewImageIndex,
    reviewModalId
  } = useUI();

  const writeReviewRef = useRef(undefined);
  const allReviewsRef = useRef(undefined);

  const count = currentVariantReviewData?.count;
  const avgScore = currentVariantReviewData?.avgScore;

  skuList = updateSkuListBasedOnProductParams(selectedVariant?.sku, skuList, selectedVariant);

  const fetchReviews = async (skuList, pageNumber) => {
    const data = await getReviews(skuList, pageNumber);
    if (data?.reviews) {
      setAllReviews((prevReviews) => {
        const newReviews = [...prevReviews];
        data.reviews.forEach((review) => {
          if (!newReviews.find((r) => r.id === review.id)) {
            newReviews.push(review);
          }
        });
        return newReviews;
      });
    }
    return data;
  };

  const loadPage = async (pageNumber) => {
    setPage(pageNumber);
    const data = await fetchReviews(skuList, pageNumber);
    // console.log('[CustomerReviewsFromDb] First Page of loaded reviews:', data?.reviews);
    setReviewsToShow(data?.reviews || []);
    if (pageNumber > 0) {
      setTimeout(() => allReviewsRef?.current?.scrollIntoView(), 100);
    }
  };

  useEffect(() => {
    const loadInitialPage = async () => {
      await loadPage(0);
    };
    loadInitialPage();
  }, [selectedVariant]);

  useEffect(() => {
    if (router?.query?.review === '') {
      setShowWriteReview(true);
      writeReviewRef.current?.scrollIntoView();
    }
  }, [router?.query]);

  if (!reviewsToShow?.length) return null;

  const currentModalReview = reviewsToShow?.find((x) => x?.id === reviewModalId);

  let isAdminUser = false;
  const userIpAddress = readCookie('sa_ipAddress');
  if (adminIpList?.includes(userIpAddress)) {
    isAdminUser = true;
  }
  // console.log('[OneReview]: isAdminUser', isAdminUser);

  const showModal = isMobile ? displayIndividualReviewModalMobile : displayIndividualReviewModal;

  const handleLoadMore = async () => {
    const nextPageToFetch = lastFetchedPage + 1;
    await fetchReviews(skuList, nextPageToFetch);
    setLastFetchedPage(nextPageToFetch);
  };

  return (
    <>
      <div className="font-sans bg-neutral-50 pt-3 lg:pt-8 pb-4 lg:pb-16 shadow-inner">
        <div className="lg:flex justify-center items-start gap-6 max-w-[1300px] m-auto">
          <div className="pb-2 px-2 lg:p-0 lg:my-0 lg:mx-0">
            <div className="w-full flex lg:flex-col items-stretch justify-start gap-3 lg:gap-2">
              <div>
                <h3
                  className={cn(
                    `block font-oswald text-lg md:text-2xl uppercase text-center lg:px-0 lg:pt-0 lg:mb-0 whitespace-pre`,
                    {
                      'pt-0': showWriteReviewButton,
                      'pt-1.5': !showWriteReviewButton
                    }
                  )}>
                  Customer Reviews
                </h3>
                <div>
                  {showWriteReviewButton > 0 && (
                    <div
                      className="block items-center justify-center uppercase md:font-medium text-center text-sm tracking-wide px-3 py-2 md:py-2 rounded cursor-pointer disabled:border-gray-300 disabled:bg-gray-300 disabled:cursor-not-allowed text-white bg-neutral-800 hover:bg-neutral-700 active:bg-neutral-800 transition ease-in-out duration-150 align-center gap-3"
                      onClick={() => setShowWriteReview(true)}>
                      Write a review
                    </div>
                  )}
                </div>
              </div>
              <div className="px-2 py-2 lg:px-3 bg-white rounded-md border shadow-sm">
                <RatingSummary avgScore={avgScore} count={count} />
              </div>
            </div>
          </div>

          <div className="lg:w-8/12">
            <div ref={writeReviewRef}>
              <WriteReview
                sku={sku ?? skuList[0]}
                showWriteReview={showWriteReview}
                setShowWriteReview={setShowWriteReview}
                setShowWriteReviewButton={setShowWriteReviewButton}
              />
            </div>

            <div className="px-2 lg:px-0 mt-2 lg:mt-0">
              <CustomerPhotosFromReviewsGrid
                skuList={skuList}
                isMobile={isMobile}
              />
            </div>

            <div className="px-3 lg:px-0">
              <h3
                className="mt-4 lg:mb-2 lg:mt-0 font-oswald text-lg md:text-2xl uppercase w-full text-left mb-2">
                {`${count} Customer Reviews`}
              </h3>
            </div>

            <div id={'all-reviews'} ref={allReviewsRef}>
              {reviewsToShow?.length > 0 &&
                reviewsToShow?.map((review, index) => {
                  const id = review?.id ?? index;
                  return (
                    <div key={`review-${id}`} className="mb-4 mx-2 lg:mx-0">
                      <div className="bg-white px-1 rounded-md border shadow-sm">
                        <DisplayOneReviewForMobileAndDesktop
                          review={review}
                          reviewIndex={index}
                          isAdminUser={isAdminUser}
                          isMobile={isMobile}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            {isMobile && showModal && (
              <ReviewsLightboxCarouselMobile
                show={showModal}
                initialImageIndex={initialReviewImageIndex}
                reviewId={reviewModalId}
                close={closeIndividualReviewModal}
                allReviews={allReviews}
                handleLoadMore={handleLoadMore}
              />
            )}

            {!isMobile && showModal && (
              <ReviewPopupIndividualV2
                currentReview={currentModalReview}
                show={showModal}
                close={closeIndividualReviewModal}
                initialImageIndex={initialReviewImageIndex}
              />
            )}

            <PaginationV2 count={count} page={page} loadPage={loadPage} isMobile={isMobile} />
          </div>
        </div>
      </div>
    </>
  );
};

const RatingSummary = ({avgScore, count}) => {
  if (!avgScore) return null;

  return (
    <div>
      <OnlyDesktop>
        <RatingSummaryDesktop avgScore={avgScore} count={count} />
      </OnlyDesktop>
      <OnlyMobile>
        <RatingSummaryMobile avgScore={avgScore} count={count} />
      </OnlyMobile>
    </div>
  );
};

const useReviews = () => {
  const cachedReviewsRef = useRef({});

  const getReviews = async (skuList, page) => {
    const cacheKey = `${skuList?.join(',')}_${page}`;

    if (cachedReviewsRef.current[cacheKey]) {
      return cachedReviewsRef?.current?.[cacheKey];
    }

    const skus = encodeURIComponent(skuList.join(','));
    const apiUrl = `/api/reviews/skuList?skus=${skus}&page=${page}`;

    try {
      const response = await fetch(apiUrl);
      // Check if the response is ok (status is 200-299)
      if (!response?.ok) {
        console.error(`Failed to fetch reviews: ${response?.statusText}`);
        return null;
      }
      const reviews = await response.json();
      cachedReviewsRef.current[cacheKey] = reviews;
      return reviews;
    } catch (e) {
      console.error(`Error fetching reviews: ${e?.message}`);
      return null;
    }
  };

  return {
    getReviews
  };
};

export const ShowStarRating = ({stars, size = 16}) => {
  const five = new Array(5).fill(null);
  return (
    <div>
      <HStack className="justify-start">
        {five?.map((x, index) => (
          <div key={index}>
            <SvgStar size={size} color={index < stars ? '#f5cc42' : '#aaa'} />
          </div>
        ))}
      </HStack>
    </div>
  );
};

export const ReviewImages = ({images, review, reviewIndex, isMobile = false}) => {
  // console.log("[ReviewImages]: review", review);
  const {openIndividualReviewModal} = useUI();

  if (!images || !images?.length) return null;

  // Deduplicate images if duplicates exist
  const uniqueImages = [...new Set(images)];

  return (
    <div className="flex flex-wrap gap-2 w-full">
      {uniqueImages?.map((x, index) => (
        <div
          key={`review-image-${index}`}
          className="rounded-md max-w-[30%] bg-[#c4c4c4] bg-spinner w-[150px] aspect-square"
        >
          <Image
            src={x}
            className="cursor-pointer rounded-md object-cover object-center aspect-square relative"
            width={150}
            height={150}
            quality={95}
            onClick={(e) => {
              e.preventDefault();
              openIndividualReviewModal(index, review.id, reviewIndex, isMobile);
            }}
            onLoad={(e) => {
              e.target?.parentElement?.classList?.remove('bg-spinner');
            }}
            sizes="150px"
            alt={`Review image #${index + 1} ${
              review?.reviewer?.name ? `from ${review.reviewer.name}` : ''
            }: ${review?.title}`}
          />
        </div>
      ))}
    </div>
  );
};

export const ReviewStarsWithCountMobile = ({stars, size = 16, count = null}) => {
  const five = new Array(5).fill(null);
  return (
    <div>
      <HStack className="justify-start">
        {five.map((x, index) => (
          <div key={index}>
            <SvgStar size={size} color={index < stars ? '#f5cc42' : '#aaa'} />
          </div>
        ))}

        {count && (
          <div className="ml-1.5" style={{fontSize: '14px', color: 'rgb(1,100,1)'}}>
            {count}
          </div>
        )}
      </HStack>
    </div>
  );
};
